import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import FileCardData from '../../data/FileCardData';
import FileCardList from '../../components/file-card/FileCardList';

function SalesMaterialFR() {

  const request = FileCardData.sort((a,b)=>a.product.localeCompare(b.product)).filter(
    item => {
      return (
        item
        .type
        .toLowerCase()
        === 'sales material' &&
        item
        .language
        .toLowerCase()
        === 'french'
      );
    }
  );

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Sales material - French</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="M84 22h-8V12c0-5.084-2.916-8-8-8H12c-5.084 0-8 2.916-8 8v68c0 9.492 7.85 12 12 12h68c.08 0 8-.114 8-10V30c0-5.084-2.916-8-8-8zM16.009 88C15.189 87.993 8 87.706 8 80V12c0-2.878 1.122-4 4-4h56c2.879 0 4 1.122 4 4v70c0 2.632.562 4.571 1.387 6H16.009zM88 82c0 5.599-3.355 5.98-4 6h-4c-.163 0-4-.068-4-6V26h8c2.879 0 4 1.122 4 4v52zM18 56h14a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2H18a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2zm2-24h10v20H20V32zm44-2a2 2 0 0 1-2 2H42a2 2 0 0 1 0-4h20a2 2 0 0 1 2 2zm0 48a2 2 0 0 1-2 2H18a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zm0-12a2 2 0 0 1-2 2H18a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zm0-48a2 2 0 0 1-2 2H18a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zm0 24a2 2 0 0 1-2 2H42a2 2 0 0 1 0-4h20a2 2 0 0 1 2 2zm0 12a2 2 0 0 1-2 2H42a2 2 0 0 1 0-4h20a2 2 0 0 1 2 2z"/>}
          title={'Sales material'}
          subtitle={'French'}
        />
        <div className="pbg-main-container">
          {request.length === 0
            ? <div className="pbg-file-card-list-empty"><span>No files found</span></div>
            : <FileCardList request={request} />
          }
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(SalesMaterialFR);