import React, {useState, useEffect} from 'react';
import './App.scss';
import {AnimatePresence} from 'framer-motion';
import {Routes, Route, useLocation} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {GlobalContext} from './global';

import Home from './pages/Home';
import Search from './pages/search/Search';
import ProductCatalogs from './pages/productcatalogs/ProductCatalogs';

import Compliance from './pages/compliance/Compliance';
import Licenses from './pages/compliance/Licenses';

import Doc from './pages/doc/Doc';
import DocEN from './pages/doc/DocEN';
import DocFI from './pages/doc/DocFI';
import DocFR from './pages/doc/DocFR';
import DocDE from './pages/doc/DocDE';
import DocPL from './pages/doc/DocPL';
import DocES from './pages/doc/DocES';

import Manuals from './pages/manuals/Manuals';
import ManualsEN from './pages/manuals/ManualsEN';
import ManualsSV from './pages/manuals/ManualsSV';
import ManualsNL from './pages/manuals/ManualsNL';
import ManualsFI from './pages/manuals/ManualsFI';
import ManualsFR from './pages/manuals/ManualsFR';
import ManualsDE from './pages/manuals/ManualsDE';
import ManualsIT from './pages/manuals/ManualsIT';
import ManualsNB from './pages/manuals/ManualsNB';
import ManualsPL from './pages/manuals/ManualsPL';
import ManualsES from './pages/manuals/ManualsES';

import ProductSheets from './pages/productsheets/ProductSheets';
import ProductSheetsEN from './pages/productsheets/ProductSheetsEN';
import ProductSheetsSV from './pages/productsheets/ProductSheetsSV';
import ProductSheetsNL from './pages/productsheets/ProductSheetsNL';
import ProductSheetsFI from './pages/productsheets/ProductSheetsFI';
import ProductSheetsFR from './pages/productsheets/ProductSheetsFR';
import ProductSheetsDE from './pages/productsheets/ProductSheetsDE';
import ProductSheetsIT from './pages/productsheets/ProductSheetsIT';
import ProductSheetsNB from './pages/productsheets/ProductSheetsNB';
import ProductSheetsPL from './pages/productsheets/ProductSheetsPL';
import ProductSheetsES from './pages/productsheets/ProductSheetsES';
import ProductSheetsSVFI from './pages/productsheets/ProductSheetsSVFI';

import SalesMaterial from './pages/salesmaterial/SalesMaterial';
import SalesMaterialEN from './pages/salesmaterial/SalesMaterialEN';
import SalesMaterialSV from './pages/salesmaterial/SalesMaterialSV';
import SalesMaterialNB from './pages/salesmaterial/SalesMaterialNB';
import SalesMaterialNL from './pages/salesmaterial/SalesMaterialNL';
import SalesMaterialFI from './pages/salesmaterial/SalesMaterialFI';
import SalesMaterialDE from './pages/salesmaterial/SalesMaterialDE';
import SalesMaterialDECH from './pages/salesmaterial/SalesMaterialDECH';
import SalesMaterialFR from './pages/salesmaterial/SalesMaterialFR';
import SalesMaterialES from './pages/salesmaterial/SalesMaterialES';

import ProductImages from './pages/productimages/ProductImages';
import ProductImagesDIM01 from './pages/productimages/ProductImagesDIM01';
import ProductImagesDIM012P from './pages/productimages/ProductImagesDIM012P';
import ProductImagesDIM02 from './pages/productimages/ProductImagesDIM02';
import ProductImagesBAT01 from './pages/productimages/ProductImagesBAT01';
import ProductImagesCTR01 from './pages/productimages/ProductImagesCTR01';
import ProductImagesDAL01 from './pages/productimages/ProductImagesDAL01';
import ProductImagesJAL01 from './pages/productimages/ProductImagesJAL01';
import ProductImagesDWN01 from './pages/productimages/ProductImagesDWN01';
import ProductImagesDWN02 from './pages/productimages/ProductImagesDWN02';
import ProductImagesEXT01 from './pages/productimages/ProductImagesEXT01';
import ProductImagesGWY01 from './pages/productimages/ProductImagesGWY01';
import ProductImagesLED10 from './pages/productimages/ProductImagesLED10';
import ProductImagesLED75 from './pages/productimages/ProductImagesLED75';
import ProductImagesLST01 from './pages/productimages/ProductImagesLST01';
import ProductImagesMNT01 from './pages/productimages/ProductImagesMNT01';
import ProductImagesMNT02 from './pages/productimages/ProductImagesMNT02';
import ProductImagesOUT01 from './pages/productimages/ProductImagesOUT01';
import ProductImagesREL012P from './pages/productimages/ProductImagesREL012P';
import ProductImagesREL02 from './pages/productimages/ProductImagesREL02';
import ProductImagesRTR01 from './pages/productimages/ProductImagesRTR01';
import ProductImagesSPR01 from './pages/productimages/ProductImagesSPR01';
import ProductImagesVRI01 from './pages/productimages/ProductImagesVRI01';
import ProductImagesVRI02 from './pages/productimages/ProductImagesVRI02';
import ProductImagesWPH01 from './pages/productimages/ProductImagesWPH01';
import ProductImagesWRT01 from './pages/productimages/ProductImagesWRT01';
import ProductImagesWMS01 from './pages/productimages/ProductImagesWMS01';
import ProductImagesWIN01 from './pages/productimages/ProductImagesWIN01';
import ProductImagesCCL01 from './pages/productimages/ProductImagesCCL01';

import Dialux from './pages/dialux/Dialux';

import PressImages from './pages/pressimages/PressImages';
import PageNotFound from './pages/PageNotFound';

function App() {
  const [menuState, setMenuState] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const loadingscreen = document.getElementById('pbg-loading');
  if(loadingscreen) {
    setTimeout(() => {
      document.body.style.overflow = ('unset');
      loadingscreen.classList.add('hide');
      setLoading(false)
    }, 1000);
  }
  useEffect(() => {
    setTimeout(()=>{
      window.scrollTo(0, 0);
    }, 500)
  }, [location]);

  return (
    !loading && (
      <>
      <HelmetProvider>
      <GlobalContext.Provider value={{menuState, setMenuState}}>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/product-catalogs" element={<ProductCatalogs />} />

          <Route path="/compliance" element={<Compliance />} />
          <Route path="/licenses" element={<Licenses />} />

          <Route path="/doc" element={<Doc />} />
          <Route path="/doc/en" element={<DocEN />} />
          <Route path="/doc/fi" element={<DocFI />} />
          <Route path="/doc/fr" element={<DocFR />} />
          <Route path="/doc/de" element={<DocDE />} />
          <Route path="/doc/pl" element={<DocPL />} />
          <Route path="/doc/es" element={<DocES />} />

          <Route path="/manuals" element={<Manuals />} />
          <Route path="/manuals/en" element={<ManualsEN />} />
          <Route path="/manuals/sv" element={<ManualsSV />} />
          <Route path="/manuals/nl" element={<ManualsNL />} />
          <Route path="/manuals/fi" element={<ManualsFI />} />
          <Route path="/manuals/fr" element={<ManualsFR />} />
          <Route path="/manuals/de" element={<ManualsDE />} />
          <Route path="/manuals/it" element={<ManualsIT />} />
          <Route path="/manuals/nb" element={<ManualsNB />} />
          <Route path="/manuals/pl" element={<ManualsPL />} />
          <Route path="/manuals/es" element={<ManualsES />} />

          <Route path="/product-sheets" element={<ProductSheets />} />
          <Route path="/product-sheets/en" element={<ProductSheetsEN />} />
          <Route path="/product-sheets/sv" element={<ProductSheetsSV />} />
          <Route path="/product-sheets/nl" element={<ProductSheetsNL />} />
          <Route path="/product-sheets/fi" element={<ProductSheetsFI />} />
          <Route path="/product-sheets/fr" element={<ProductSheetsFR />} />
          <Route path="/product-sheets/de" element={<ProductSheetsDE />} />
          <Route path="/product-sheets/it" element={<ProductSheetsIT />} />
          <Route path="/product-sheets/nb" element={<ProductSheetsNB />} />
          <Route path="/product-sheets/pl" element={<ProductSheetsPL />} />
          <Route path="/product-sheets/es" element={<ProductSheetsES />} />
          <Route path="/product-sheets/sv-fi" element={<ProductSheetsSVFI />} />

          <Route path="/sales-material" element={<SalesMaterial />} />
          <Route path="/sales-material/en" element={<SalesMaterialEN />} />
          <Route path="/sales-material/sv" element={<SalesMaterialSV />} />
          <Route path="/sales-material/nb" element={<SalesMaterialNB />} />
          <Route path="/sales-material/nl" element={<SalesMaterialNL />} />
          <Route path="/sales-material/fi" element={<SalesMaterialFI />} />
          <Route path="/sales-material/de" element={<SalesMaterialDE />} />
          <Route path="/sales-material/de-ch" element={<SalesMaterialDECH />} />
          <Route path="/sales-material/fr" element={<SalesMaterialFR />} />
          <Route path="/sales-material/es" element={<SalesMaterialES />} />

          <Route path="/product-images" element={<ProductImages />} />
          <Route path="/product-images/dim-01" element={<ProductImagesDIM01 />} />
          <Route path="/product-images/dim-01-2p" element={<ProductImagesDIM012P />} />
          <Route path="/product-images/dim-02" element={<ProductImagesDIM02 />} />
          <Route path="/product-images/bat-01" element={<ProductImagesBAT01 />} />
          <Route path="/product-images/ctr-01" element={<ProductImagesCTR01 />} />
          <Route path="/product-images/dal-01" element={<ProductImagesDAL01 />} />
          <Route path="/product-images/jal-01" element={<ProductImagesJAL01 />} />
          <Route path="/product-images/dwn-01" element={<ProductImagesDWN01 />} />
          <Route path="/product-images/dwn-02" element={<ProductImagesDWN02 />} />
          <Route path="/product-images/ext-01" element={<ProductImagesEXT01 />} />
          <Route path="/product-images/gwy-01" element={<ProductImagesGWY01 />} />
          <Route path="/product-images/led-10" element={<ProductImagesLED10 />} />
          <Route path="/product-images/led-75" element={<ProductImagesLED75 />} />
          <Route path="/product-images/lst-01" element={<ProductImagesLST01 />} />
          <Route path="/product-images/mnt-01" element={<ProductImagesMNT01 />} />
          <Route path="/product-images/mnt-02" element={<ProductImagesMNT02 />} />
          <Route path="/product-images/out-01" element={<ProductImagesOUT01 />} />
          <Route path="/product-images/rel-01-2p" element={<ProductImagesREL012P />} />
          <Route path="/product-images/rel-02" element={<ProductImagesREL02 />} />
          <Route path="/product-images/rtr-01" element={<ProductImagesRTR01 />} />
          <Route path="/product-images/spr-01" element={<ProductImagesSPR01 />} />
          <Route path="/product-images/vri-01" element={<ProductImagesVRI01 />} />
          <Route path="/product-images/vri-02" element={<ProductImagesVRI02 />} />
          <Route path="/product-images/wph-01" element={<ProductImagesWPH01 />} />
          <Route path="/product-images/wrt-01" element={<ProductImagesWRT01 />} />
          <Route path="/product-images/wms-01" element={<ProductImagesWMS01 />} />
          <Route path="/product-images/win-01" element={<ProductImagesWIN01 />} />
          <Route path="/product-images/ccl-01" element={<ProductImagesCCL01 />} />

          <Route path="/dialux" element={<Dialux />} />

          <Route path="/press-images" element={<PressImages />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AnimatePresence>
      </GlobalContext.Provider>
      </HelmetProvider>
      </>
    )
  );
  
};
export default App;